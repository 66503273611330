import React, { useState, useEffect } from "react";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import DatePicker from "react-datepicker";
import { parseISO, format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import { formatToDate } from "utils/formatTime";
import axios from "axios";
import { useMediaQuery } from "@mui/material";
import { FaPlus, FaMinus, FaCheck } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { getToken } from "utils/getToken";
import { Listbox } from "@headlessui/react";
import { HiOutlineSelector } from "react-icons/hi";
import SyncLoader from "components/Loaders/SyncLoader";
import CreateSubscriptionModal from "components/CreateSubscription/CreateSubscriptionForm";
import { HiExternalLink } from "react-icons/hi";
import UserDataPopup from "components/UserData/UserDataPopup";
import { MdCancel } from "react-icons/md";

const filterOptions = [
  { value: "all", name: "All" },
  { value: "HOLISTIC_OLD", name: "HOLISTIC_OLD" },
  { value: "HOLISTIC_NEW", name: "HOLISTIC_NEW" },
  { value: "BASIC", name: "BASIC" },
  { value: "PRIME", name: "PRIME" },
  { value: "ESSENTIAL", name: "ESSENTIAL" },
  { value: "BTP", name: "BTP" },
  { value: "WTP", name: "WTP" },
  { value: "STP", name: "STP" },
  { value: "TTP", name: "Toilet Training Program" },
  { value: "COMPLEMENTARY_CHAT", name: "COMPLEMENTARY CHAT" },
  { value: "BASIC_CHAT", name: "BASIC CHAT" },
  { value: "FREE_PREVIEW", name: "FREE PREVIEW" },
  { value: "PRIME_LIMITED_TRIAL", name: "PRIME LIMITED TRIAL" },
  { value: "BASIC_499", name: "BASIC 499" },
  { value: "YOGA BASIC", name: "YOGA_BASIC" },
  { value: "OLD_BASIC", name: "OLD BASIC" },
  { value: "OLD_PRIME", name: "OLD PRIME" },
  { value: "OLD_PRIMIUM", name: "OLD PRIMIUM" },
];

const sortingOptions = [
  { value: "asc", name: "Ascending" },
  { value: "desc", name: "Descending" },
];

const Subscription = () => {
  const [token, setToken] = useState(getToken());
  const [data, setData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterPage, setFilterPage] = useState("");
  const [comment, setComment] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [editableMaxCalls, setEditableMaxCalls] = useState("");
  const [isCreateSubscriptionModalOpen, setIsCreateSubscriptionModalOpen] =
    useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [reasonData, setReasonData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [commentError, setCommentError] = useState(false); // New state variable for comment error message
  const [selectedFilters, setSelectedFilters] = useState(filterOptions);
  const [selectedFilterDate, setSelectedFilterDate] = useState(null);
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [sortingMethod, setSortingMethod] = useState(sortingOptions[1]);
  const [fetchingSubscriptionData, setFetchingSubscriptionData] =
    useState(false);
  const [isPDataModalOpen, setIsPDataModalOpen] = useState(false);
  const [isFilterSelectedForConsultation, setIsFilterSelectedForConsultation] =
    useState(false);
  const [consultationTypeFilter, setConsultationTypeFilter] = useState("");
  const [doctorsData, setDoctorsData] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [consultType, setConsultType] = useState("");
  const [assignedDoctors, setAssignedDoctors] = useState({});
  const [docsObj, setDocsObj] = useState([]); // gotten from assignedDoctors for display in UI

  const handlePDataModalClose = () => {
    setIsPDataModalOpen(false);
    setSelectedUser(null);
  };

  const handlePDataModal = (row) => {
    setSelectedUser(row.phone_number);
    // console.log(row.Chat_started_by);
    setIsPDataModalOpen(true);
  };

  const fetchData = async () => {
    try {
      let url = process.env.REACT_APP_API_URL + "subscription/subscriptions/";
      if (filterText) {
        url += `?mobile=${encodeURIComponent(filterText)}`;
      }

      let planNameFilters = [];
      let formattedPlanNameFilters;

      if (selectedFilters.length > 0) {
        selectedFilters.forEach((filter) => {
          if (filter.value !== "all") {
            planNameFilters.push(filter.value);
          }
        });
        formattedPlanNameFilters = `${planNameFilters.join(",")}`;
      }

      if (isFilterChanged) {
        setCurrentPage(1);
      }
      if (isFilterChanged) {
        setIsFilterChanged(false);
      }

      setFetchingSubscriptionData(true);

      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          names:
            selectedFilters.length > 0 &&
            !selectedFilters.includes(filterOptions[0])
              ? formattedPlanNameFilters
              : null,
          start_time: selectedFilterDate ? selectedFilterDate : null,
          sorting: sortingMethod ? sortingMethod.value : null,
          page: isFilterChanged ? 1 : currentPage,
          consultation_type:
            consultationTypeFilter !== "" ? consultationTypeFilter : null,
        },
      });

      const jsonData = response.data;
      setData(jsonData.results);

      const totalItems = jsonData.count; // Total number of items from the API response
      const itemsPerPage = 50; // Number of items displayed per page

      const totalPages = Math.ceil(totalItems / itemsPerPage);
      setTotalPages(totalPages); // Set the total number of pages
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setFetchingSubscriptionData(false);
    }
  };
  useEffect(() => {
    if (!isSearchButtonClicked) {
      fetchData();
      console.log("data:", data);
    }
  }, [
    currentPage,
    isSearchButtonClicked,
    selectedFilters,
    selectedFilterDate,
    sortingMethod,
    consultationTypeFilter,
  ]);

  const handleSearchButtonClick = async () => {
    setLoading(true); // Set loading to true before starting the search

    try {
      await fetchData(); // Call the API with the updated filterText
      setIsSearchButtonClicked(true); // Set the flag to true indicating search button is clicked
    } catch (error) {
      console.log("No data Available");
      // Handle the error if needed
    }

    setLoading(false); // Set loading to false after the search button click is complete
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handlePageSearch = () => {
    const pageNumber = parseInt(filterPage);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setFilterText(""); // Clear the filterText after navigation
      setIsSearchButtonClicked(false); // Reset the search button click state

      setCurrentPage(pageNumber);
    }
  };

  const handleEditClick = async (rowIndex) => {
    const user = data[rowIndex];
    setSelectedUser(user);

    try {
      // Make the API call to g\et the data for the selected user
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `subscription/subscriptions/?subs_id=${user.id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      const userData = response.data.results[0]; // Assuming the API returns an array of results
      console.log(userData);
      setStartDate(parseISO(userData.s_start_time));
      setEndDate(parseISO(userData.s_end_time));
      setComment(userData.comment);
      setEditableMaxCalls(userData.max_calls);
      setShowModal(true);
      setPaymentAmount(userData.payment_amount);
      setAssignedDoctors(userData.assigned_doctor);

      // Fetch reason data for the selected user
      const reasonResponse = await axios.get(
        process.env.REACT_APP_API_URL +
          `subscription/subshistory/?suhs_id=${user.id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      const reasonData = reasonResponse.data;
      setReasonData(reasonData);
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle the error if needed
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilterText(value);
    setIsSearchButtonClicked(false); // Reset the search button click state when filter text changes
  };

  const handleNameFilterChange = (newSelectedStatuses) => {
    if (newSelectedStatuses.includes(filterOptions[0])) {
      if (
        newSelectedStatuses.length === filterOptions.length - 1 &&
        selectedFilters.includes(filterOptions[0])
      ) {
        setSelectedFilters(
          newSelectedStatuses.filter((status) => status.value !== "all")
        );
        setIsFilterChanged(true);
      } else {
        setSelectedFilters(filterOptions);
        setIsFilterChanged(true);
      }
    } else if (
      !selectedFilters.includes(filterOptions[0]) &&
      newSelectedStatuses.length >= filterOptions.length - 1
    ) {
      setSelectedFilters(filterOptions);
      setIsFilterChanged(true);
    } else if (
      selectedFilters.includes(filterOptions[0]) &&
      newSelectedStatuses.length >= filterOptions.length - 1
    ) {
      setSelectedFilters([]);
      setIsFilterChanged(true);
    } else {
      setSelectedFilters(newSelectedStatuses);
      setIsFilterChanged(true);
    }
  };

  const assignedDocData = docsObj.map((doc) => {
    return {
      id: doc.id,
      // [doc.type]: {
      //   name: doc.doctor,
      // },
    };
  });
  const handleSaveClick = async () => {
    const updatedUser = {
      ...selectedUser,
      s_start_time: format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      s_end_time: format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      comment: comment,
      max_calls: parseInt(editableMaxCalls),
    };
    console.log("Updated User Comment:", updatedUser.comment);
    // Check if the comment is empty, null, or undefined
    if (!comment || comment.trim() === "") {
      setCommentError(true);
      return;
    }
    try {
      const updateData = JSON.stringify({
        subscription_id: updatedUser.id,
        s_end_time: updatedUser.s_end_time,
        s_start_time: updatedUser.s_start_time,
        max_calls: updatedUser.max_calls,
        reason: comment,
        payment_number: updatedUser.phone_number,
        payment_amount: paymentAmount,
        assigned_doctor: {
          [consultType]: parseInt(selectedDoctor)
        },
      });

      const config = {
        method: "post",
        url: process.env.REACT_APP_API_URL + "subscription/update/",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        data: updateData,
      };

      // Update the data on the server using Axios
      const response = await axios(config);

      // Update the data in the state
      setData((prevData) =>
        prevData.map((user) =>
          user.id === updatedUser.id ? updatedUser : user
        )
      );
      toast.success("Details updated successfully.");
      console.log(response.data); // Do something with the response if needed
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleCommentChange = (e) => {
    const { value } = e.target;
    setComment(value);
  };

  const handleMaxCallsChange = (e) => {
    const { value } = e.target;
    setEditableMaxCalls(value);
  };

  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const numberOfButtons = isSmallScreen ? 4 : 10;

  // Function to toggle the modal's visibility
  const toggleModal = () => {
    setIsCreateSubscriptionModalOpen(!isCreateSubscriptionModalOpen);
  };

  const handleDateChange = (date) => {
    setSelectedFilterDate(date);
    setIsFilterChanged(true);
  };

  const handleRefresh = async () => {
    fetchData();
  };

  const handleFilterChangeForConsultationType = (value) => {
    setConsultationTypeFilter(value);
    setIsFilterSelectedForConsultation(true);
    setCurrentPage(1);
  };

  const FetchDoctorGrowthandLactation = async (category) => {
    if (category !== "") {
      try {
        const { data } = await axios.get(
          category === "lactation" || category === "growth"
            ? `${process.env.REACT_APP_API_URL}doctors/doctors/all/?category=${category}`
            : `${process.env.REACT_APP_API_URL}doctors/doctors/all`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${getToken()}`,
            },
          }
        );
        setDoctorsData(data);
      } catch (error) {
        console.error("Error fetching doctors:", error);
        toast.error("Failed to fetch doctors");
      }
    }
  };

  useEffect(() => {
    FetchDoctorGrowthandLactation(consultType);
  }, [consultType]);

  useEffect(() => {
    setSelectedDoctor(doctorsData[0]?.id);
  }, [doctorsData]);

  useEffect(() => {
    if (showModal) {
      if (assignedDoctors !== null && assignedDoctors !== undefined) {
        const objKeys = Object.keys(assignedDoctors).map((key) => {
          return {
            type: key,
            doctor: assignedDoctors[key]?.name,
            id: assignedDoctors[key]?.id,
          };
        });
        if (objKeys.length !== 0) {
          setDocsObj(objKeys);
        } else {
          setDocsObj([]);
        }
      }
    } else {
      setDocsObj([]);
    }
  }, [showModal]);
  return (
    <div className="mx-auto px-4 md:mx-0 md:w-full md:p-4">
      <div className="my-6 flex items-center justify-between">
        <h1 className="items-center justify-center text-center text-4xl font-bold capitalize text-navy-700 dark:text-white">
          Subscription
        </h1>

        <div className="flex flex-col items-center justify-between md:flex-row">
          <button
            className="mt-2 mb-4 w-full rounded-lg bg-blue-500 px-3 py-4 text-white hover:bg-blue-600 md:hidden"
            onClick={toggleModal}
          >
            {isCreateSubscriptionModalOpen ? (
              <FaMinus className="mr-2 inline-block" />
            ) : (
              <>
                <FaPlus className="mr-2 inline-block" />
              </>
            )}
            Create Subscription
          </button>

          <div className="flex items-center gap-3">
            <button
              className="my-2 mt-2 hidden items-center rounded-lg bg-blue-500 px-5 py-2.5 text-white hover:bg-blue-600  md:flex"
              onClick={toggleModal}
            >
              {isCreateSubscriptionModalOpen ? (
                <FaMinus className="mr-2 inline-block" />
              ) : (
                <>
                  <FaPlus className="mr-2 inline-block" /> Create Subscription
                </>
              )}
            </button>

            <div className="hidden md:block">
              <button
                disabled={fetchingSubscriptionData}
                className="rounded-lg bg-blue-500 px-3 py-2.5 text-white hover:bg-blue-600 md:px-6"
                onClick={() => handleRefresh()}
              >
                Refresh
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-[1fr,2fr]">
        {/* Filter input */}
        <div className="flex items-center justify-between gap-6">
          <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-center gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
            <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
              <input
                type="text"
                placeholder=" User Phone Number"
                value={filterText}
                onChange={handleFilterChange}
                className="block h-full w-full rounded-full bg-lightPrimary px-4 text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
              />
            </div>
            <button
              className="hover:bg-blue-6000 mx-2 cursor-pointer rounded-full bg-blue-500 px-4 py-2 text-white hover:bg-blue-300"
              onClick={handleSearchButtonClick}
              disabled={loading} // Disable the button while loading
            >
              {loading ? (
                "Loading..."
              ) : (
                <div className="flex">
                  {/* <FiSearch  /> */}
                  Search
                </div>
              )}
            </button>
          </div>

          <div className="md:hidden">
            <button
              disabled={fetchingSubscriptionData}
              className="rounded-lg bg-blue-500 px-3 py-2.5 text-white hover:bg-blue-600 md:px-6"
              onClick={() => handleRefresh()}
            >
              Refresh
            </button>
          </div>
        </div>

        {/* Filters */}
        <div className="my-4 flex items-center justify-end">
          <div className="flex items-center justify-start gap-4">
            {/* filter by consultation tye */}
            <div className="flex">
              <div>
                <select
                  className={`focus:ring-primary rounded-md border border-gray-300 bg-white px-3 py-2 text-base ring-opacity-5 hover:bg-gray-50 focus:outline-none focus:ring-1
             ${consultationTypeFilter !== "" ? "text-green-500" : "text-black"}
            `}
                  value={consultationTypeFilter}
                  onChange={(e) => {
                    handleFilterChangeForConsultationType(e.target.value);
                  }}
                >
                  <option value="">Check Pending Calls</option>
                  <option value="growth">Growth</option>
                </select>
              </div>
              {consultationTypeFilter !== "" && (
                <span
                  className="relative left-1 top-3 cursor-pointer text-red-500"
                  onClick={(e) => {
                    e.stopPropagation();
                    setConsultationTypeFilter("");
                    setIsFilterSelectedForConsultation(false);
                  }}
                >
                  <MdCancel />
                </span>
              )}
            </div>
            {/* Filter By Name */}
            <div className="relative">
              <Listbox
                value={selectedFilters}
                onChange={handleNameFilterChange}
                multiple
              >
                {({ open }) => (
                  <>
                    <div className="relative inline-block text-left">
                      <Listbox.Button
                        as="div"
                        className="inline-flex w-full items-center justify-center gap-4 rounded-md border border-gray-300 bg-white px-4 py-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                      >
                        {selectedFilters.length > 0
                          ? `${selectedFilters.length} Filter Selected`
                          : "Filter By Name"}
                        {selectedFilters.length > 0 ? (
                          <button
                            onClick={() => {
                              setSelectedFilters([]);
                              setIsFilterChanged(true);
                            }}
                            className="ml-2 flex items-center"
                          >
                            <MdOutlineClose size={22} />
                          </button>
                        ) : (
                          <HiOutlineSelector size="22" />
                        )}
                      </Listbox.Button>
                    </div>
                    <Listbox.Options
                      className="absolute mt-1 max-h-60 min-w-[180px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm"
                      style={{ display: open ? "block" : "none" }}
                    >
                      {filterOptions.map((option, index) => (
                        <Listbox.Option
                          key={index}
                          value={option}
                          className={({ active }) =>
                            `${
                              active
                                ? "bg-blue-500 text-white"
                                : "text-gray-900"
                            }
                        relative cursor-pointer select-none py-2 pl-1 pr-4`
                          }
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center gap-3">
                                <input
                                  type="checkbox"
                                  checked={selected ? true : false}
                                  className="cursor-pointer"
                                />
                                <span
                                  className={`${
                                    selected ? "font-semibold" : "font-normal"
                                  }`}
                                >
                                  {option.name}
                                </span>
                              </div>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </>
                )}
              </Listbox>
            </div>

            {/* Filter By Sorting Method */}
            <div className="relative">
              <Listbox value={sortingMethod} onChange={setSortingMethod}>
                {({ open }) => (
                  <>
                    <div className="relative inline-block text-left">
                      <Listbox.Button
                        as="div"
                        className="inline-flex w-full items-center justify-center gap-4 rounded-md border border-gray-300 bg-white px-4 py-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                      >
                        {sortingMethod
                          ? `${sortingMethod.name}`
                          : "Choose Sorting Method"}
                        {sortingMethod ? (
                          <button
                            onClick={() => setSortingMethod("")}
                            className="ml-2 flex items-center"
                          >
                            <MdOutlineClose size={22} />
                          </button>
                        ) : (
                          <HiOutlineSelector size="22" />
                        )}
                      </Listbox.Button>
                    </div>
                    <Listbox.Options
                      className="absolute mt-1 max-h-60 min-w-[180px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm"
                      style={{ display: open ? "block" : "none" }}
                    >
                      {sortingOptions.map((option, index) => (
                        <Listbox.Option
                          key={index}
                          value={option}
                          className={({ active }) =>
                            `${
                              active
                                ? "bg-blue-500 text-white"
                                : "text-gray-900"
                            }
                        relative cursor-pointer select-none py-2 pl-1 pr-4`
                          }
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center gap-3">
                                <input
                                  type="checkbox"
                                  checked={selected ? true : false}
                                  className="cursor-pointer"
                                />
                                <span
                                  className={`${
                                    selected ? "font-semibold" : "font-normal"
                                  }`}
                                >
                                  {option.name}
                                </span>
                              </div>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </>
                )}
              </Listbox>
            </div>

            {/* Date Filter */}
            <div className="relative inline-flex items-center justify-center gap-4 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50">
              <input
                id="dob"
                name="dob"
                type="date"
                value={selectedFilterDate}
                onChange={(e) => handleDateChange(e.target.value)}
                max={new Date().toISOString().split("T")[0]}
                placeholder="Filter By Date"
                className={`w-full rounded-md px-5 py-3`}
              />

              {selectedFilterDate && (
                <div className="ml-5">
                  <button
                    onClick={() => {
                      setSelectedFilterDate("");
                      setIsFilterChanged(true);
                    }}
                    className="absolute top-3 right-2"
                  >
                    <MdOutlineClose size={22} />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Filters end */}
      </div>

      {isCreateSubscriptionModalOpen && (
        <CreateSubscriptionModal onClose={toggleModal} />
      )}

      <div className="mx-auto sm:hidden">
        {/* List items */}
        {data.length > 0 ? (
          data.map((row, rowIndex) => (
            <div key={rowIndex} className="mb-4 rounded-md bg-white p-4 shadow">
              <div className="mb-2 flex justify-between">
                <div>
                  <p className="text-lg font-semibold">{row.id}</p>
                  <p>{row.phone_number}</p>
                </div>
                <div>
                  <p className="text-lg font-semibold">{row.plan_name}</p>
                </div>
              </div>
              <div className="flex justify-between">
                <div>
                  <p className="text-base font-semibold">Start Date</p>
                  <p>
                    {format(parseISO(row.s_start_time), "yyyy-MM-dd HH:mm:ss")}
                  </p>
                </div>
              </div>
              <div className="flex justify-between">
                <div>
                  <p className="text-base font-semibold">End Date</p>
                  <p>
                    {format(parseISO(row.s_end_time), "yyyy-MM-dd HH:mm:ss")}
                  </p>
                </div>
              </div>

              <div className="flex justify-between">
                <div>
                  <p className="text-base font-semibold">Max Calls</p>
                  <p>{row.max_calls}</p>
                </div>
                <div>
                  <p className="text-base font-semibold">Used Calls</p>
                  <p>{row.used_calls}</p>
                </div>
              </div>
              <div className="mt-2 flex justify-end">
                <div>
                  <FaPencilAlt
                    className="mr-2 cursor-pointer text-blue-500"
                    onClick={() => handleEditClick(rowIndex)}
                    aria-label="Edit"
                  />
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No data available</p>
        )}
      </div>
      {/* new way to show user data in modal */}
      {isPDataModalOpen && (
        <UserDataPopup
          selectedUser={selectedUser}
          noSearch={true}
          handlePDataModalClose={handlePDataModalClose}
        />
      )}

      {/* Desktop View Data */}
      {!fetchingSubscriptionData && data.length > 0 ? (
        <div className="min-h-[60vh] max-w-full overflow-y-auto">
          <table className="hidden table-auto bg-white shadow md:block">
            <thead>
              <tr className="bg-blue-200">
                <th className="border px-4 py-2">ID</th>
                <th className="border px-4 py-2">User</th>
                <th className="border px-4 py-2">Plan Name</th>
                <th className="border px-4 py-2">Start Date</th>
                <th className="border px-4 py-2">End Date</th>
                <th className="border px-4 py-2">Max Calls</th>
                <th className="border px-4 py-2">Used Calls</th>
                {/* <th className="px-4 py-2 border">Reason</th> */}
                <th className="border px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((row, rowIndex) => (
                  <tr key={rowIndex} className="hover:bg-gray-100">
                    <td className="border px-4 py-2">{row.id}</td>
                    <td className="border px-4 py-2">
                      <button
                        className="cursor-pointer text-blue-500 hover:underline"
                        onClick={() => {
                          handlePDataModal(row);
                        }}
                      >
                        <p>{row.phone_number}</p> <HiExternalLink />
                        <p className="text-blue-500">{row?.name || ""}</p>
                      </button>
                    </td>
                    <td className="border px-4 py-2">{row.plan_name}</td>
                    <td className="border px-4 py-2">
                      {format(
                        parseISO(row.s_start_time),
                        "yyyy-MM-dd HH:mm:ss"
                      )}
                    </td>
                    <td className="border px-4 py-2">
                      {format(parseISO(row.s_end_time), "yyyy-MM-dd HH:mm:ss")}
                    </td>
                    <td className="border px-4 py-2">{row.max_calls}</td>
                    <td className="border px-4 py-2">{row.used_calls}</td>
                    {/* <td className="px-4 py-2 border">{row.comment}</td> */}
                    <td className="border px-4 py-2">
                      <FaPencilAlt
                        onClick={() => handleEditClick(rowIndex)}
                        className="mr-2 cursor-pointer text-blue-500"
                        aria-label="Edit"
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="border px-4 py-2" colSpan="6">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : fetchingSubscriptionData ? (
        <div className="flex h-[70vh] items-center justify-center">
          <SyncLoader color={"#525CEB"} size={10} />
        </div>
      ) : (
        <div className="flex h-full min-h-[60vh] w-full items-center justify-center">
          <p className="text-center text-lg font-semibold">
            No Subscription Data available
          </p>
        </div>
      )}
      <div className="mt-10 flex flex-col justify-center md:mt-20 md:flex-row">
        <div className="flex justify-center md:ml-auto">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="rounded-lg bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 md:px-8"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {totalPages > 1 &&
            [
              ...Array(Math.min(numberOfButtons, totalPages - currentPage + 1)),
            ].map((_, index) => {
              const pageNumber = currentPage + index;
              return (
                <button
                  key={index}
                  onClick={() => setCurrentPage(pageNumber)}
                  disabled={pageNumber > totalPages}
                  className={`ml-2 rounded-lg px-4 py-2 ${
                    currentPage === pageNumber
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                  }`}
                >
                  {pageNumber}
                </button>
              );
            })}

          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="mx-2 rounded-lg bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 md:px-8"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 -rotate-180 transform"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className="relative mt-5 flex items-center justify-end md:ml-auto md:mt-0 ">
          <input
            type="text"
            placeholder={`Go to page (1 - ${totalPages})`}
            value={filterPage}
            onChange={(e) => setFilterPage(e.target.value)}
            className="w-full rounded-md border border-gray-400 bg-white py-2 pl-8 pr-2 text-sm font-medium text-gray-800 outline-none"
          />
          <button
            className="mx-2 cursor-pointer rounded-lg bg-blue-500 px-4 py-3 text-white hover:bg-blue-300"
            onClick={handlePageSearch}
          >
            <FiSearch
              className="h-4 w-4 cursor-pointer rounded-lg"
              onClick={handlePageSearch}
            />
          </button>
        </div>
      </div>

      {selectedUser && showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden">
          <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
          <div className="custom-scrollbar relative z-10 mx-4 h-[95vh] w-full overflow-y-auto rounded bg-white md:mx-auto md:max-w-lg">
            <div className="p-4 md:p-8">
              <h2 className="text-center text-2xl font-bold">User Data</h2>
              <div className="mt-2 space-y-4 md:mt-4">
                <div className="flex flex-col">
                  <p className="font-semibold">ID:</p>
                  <p className="rounded-md border border-gray-400 bg-gray-500 px-4 py-2">
                    {selectedUser.id}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-semibold">Phone Number:</p>
                  <p className="rounded-md border border-gray-400 bg-gray-500 px-4 py-2">
                    {selectedUser.phone_number}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-semibold">Plan Name:</p>
                  <p className="rounded-md border border-gray-400 bg-gray-500 px-4 py-2">
                    {selectedUser.plan_name}
                  </p>
                </div>
                <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-8">
                  <div className="flex flex-col">
                    <p className="font-semibold">Start Date:</p>
                    <DatePicker
                      selected={startDate}
                      onChange={handleStartDateChange}
                      className="rounded-md border border-gray-400 px-4 py-2"
                      dateFormat="dd/MM/yyyy"
                    />
                    <p className="pl-2 font-bold text-blue-800">
                      {formatToDate(startDate)}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p className="font-semibold">End Date:</p>
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      className="rounded-md border border-gray-400 px-4 py-2"
                      dateFormat="dd/MM/yyyy"
                    />
                    <p className="pl-2 font-bold text-blue-800">
                      {formatToDate(endDate)}
                    </p>
                  </div>
                </div>
                {/* Display subscription history */}
                {/* Display subscription history */}
                <div className="mt-2 md:mt-4">
                  <h3 className="text-lg font-semibold">
                    Subscription History:
                  </h3>
                  <div className="rounded-xl border border-gray-300 bg-gray-500 px-4">
                    <div className="mt-2 flex justify-between">
                      <p className="font-semibold">Reason</p>
                      <p className="font-semibold">Timestamp</p>
                    </div>
                    {reasonData.map((historyItem, index) => (
                      <div key={index} className="mt-2 flex justify-between">
                        <p>{historyItem.reason}</p>
                        <p>
                          {format(
                            parseISO(historyItem.timestamp),
                            "yyyy-MM-dd HH:mm:ss"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="flex flex-col">
                  <p className="font-semibold">Comment:</p>
                  <textarea
                    value={comment}
                    onChange={handleCommentChange}
                    className="rounded-md border border-gray-400 px-4 py-2"
                  />
                  {commentError && (
                    <p className="mt-1 text-red-500">Comment is required!</p>
                  )}
                </div>
                <div className="flex items-center gap-x-4">
                  <div className="mb-1 flex w-1/2 flex-col items-center">
                    <label className="mb-1 block text-base font-semibold md:mb-2">
                      Consultation type
                    </label>
                    <select
                      value={consultType}
                      onChange={(e) => setConsultType(e.target.value)}
                      className="mr-2 w-full rounded-md border border-gray-400 px-4 py-2 focus:border-blue-500 focus:outline-none"
                    >
                      <option value="">Choose an option</option>
                      <option value="lactation">lactation</option>
                      <option value="nutrition">nutrition</option>
                      <option value="gyne">gyne</option>
                      <option value="derma">derma</option>
                      <option value="pediatric">pediatric</option>
                      <option value="growth">growth</option>
                    </select>
                  </div>
                  <div className="mb-1 flex w-1/2 flex-col items-center">
                    <label className="mb-1 block text-base font-semibold md:mb-2">
                      Assigned Doctor
                    </label>
                    <select
                      value={selectedDoctor}
                      onChange={(e) => setSelectedDoctor(e.target.value)}
                      className="mr-2 w-full rounded-md border border-gray-400 px-4 py-2 focus:border-blue-500 focus:outline-none"
                    >
                      {doctorsData?.map((doc, i) => (
                        <option key={i} value={doc.id}>
                          {doc.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {docsObj.length > 0 ? (
                  <div className="text-center">
                    {docsObj.map((item, i) => (
                      <p key={i}>
                        <span className="font-semibold">{item.type}</span>:{" "}
                        {item.doctor}
                      </p>
                    ))}
                  </div>
                ) : null}

                <div className="flex flex-col">
                  <p className="font-semibold">Max Calls:</p>
                  <input
                    type="number"
                    value={editableMaxCalls}
                    onChange={handleMaxCallsChange}
                    className="rounded-md border border-gray-400 px-4 py-2"
                  />
                </div>
                <div className="flex flex-col">
                  <p className="font-semibold">Payment Amount:</p>
                  <input
                    type="number"
                    value={paymentAmount}
                    onChange={(e) => setPaymentAmount(e.target.value)}
                    className="rounded-md border border-gray-400 px-4 py-2"
                  />
                </div>
              </div>
              {/* Save and Cancel buttons */}
              <div className="mt-6 flex justify-end">
                <button
                  onClick={handleSaveClick}
                  className="mr-2 rounded-lg bg-green-500 px-8 py-2 text-white hover:bg-green-600"
                >
                  Save
                </button>
                <button
                  onClick={handleCloseModal}
                  className="rounded-lg bg-red-500 px-8 py-2 text-white hover:bg-red-600"
                >
                  Cancel
                </button>
              </div>
              {/* Close icon */}
              <div
                className="text-black-800 dark:text-black-100 absolute top-0 right-0 m-3 cursor-pointer transition duration-150 ease-in-out"
                onClick={handleCloseModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-label="Close"
                  className="icon icon-tabler icon-tabler-x"
                  width={20}
                  height={20}
                  viewBox="0 0 24 24"
                  strokeWidth="2.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <line x1={18} y1={6} x2={6} y2={18} />
                  <line x1={6} y1={6} x2={18} y2={18} />
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Subscription;
