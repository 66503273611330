import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  isSaveStatusButtonDisabledState,
  appointmentStatusState,
  statusModalOpenState,
} from "store/appointment/atoms/appointmentAtomState";

const appointmentStatusSelectOptions = [
  { name: "Choose a status", value: null },
  { name: "Created", value: "created" },
  { name: "Scheduled", value: "scheduled" },
  { name: "Pending Confirmation", value: "pending_confirmation" },
  { name: "On Going", value: "on_going" },
  { name: "Consultation Done", value: "consultation_done" },
  { name: "Cancelled", value: "cancelled" },
  { name: "Closed", value: "closed" },
];
function AppointmentStatusModal({ handleStatusChangeSaveClick }) {
  const [appointmentStatus, setAppointmentStatus] = useRecoilState(
    appointmentStatusState
  );
  const [isStatusModalOpen, setIsStatusModalOpen] =
    useRecoilState(statusModalOpenState);
  const isSaveStatusButtonDisabled = useRecoilValue(
    isSaveStatusButtonDisabledState
  );

  return (
    <>
      {" "}
      {isStatusModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="modal-overlay absolute h-full w-full bg-gray-900 opacity-50"></div>
          <div className="modal-container z-50 mx-auto w-96 rounded bg-white shadow-lg">
            <div className="modal-content p-6">
              <button
                className="relative left-[330px] text-gray-600 hover:text-gray-800"
                onClick={() => setIsStatusModalOpen(false)}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <h2 className="mb-4 text-lg font-semibold">Change Status</h2>
              <select
                value={appointmentStatus}
                onChange={(e) => setAppointmentStatus(e.target.value)}
                className="w-full rounded-md border p-2"
              >
                {appointmentStatusSelectOptions.map((status, index) => (
                  <option key={index} value={status.value}>
                    {status.name}
                  </option>
                ))}
              </select>
              <div className="mt-4 flex justify-end">
                <button
                  onClick={handleStatusChangeSaveClick}
                  className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                  disabled={isSaveStatusButtonDisabled}
                >
                  {isSaveStatusButtonDisabled ? "Please Wait" : "Save"}
                </button>

                <button
                  className="ml-2 rounded-md bg-gray-400 px-4 py-2 text-white"
                  onClick={() => setIsStatusModalOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AppointmentStatusModal;
